/* Add a background image or color to the entire home screen */
.home {
  min-height: calc(100vh - 200px);
  /* Ensure the home screen takes up the entire viewport height */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.header {
  text-align: center;
  color: #000;
}

/* Style the sections */
.section {
  background-color: #fff;
  /* Section background color */
  border-radius: 20px;
  /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Box shadow for a subtle lift effect */
  overflow: hidden;
  margin-bottom: 20px;
  width: 75%;
}

.section-header {
  background-color: white;
  /* Header background color */
  color: black;
  padding: 10px;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  height: 75px;
}

.section-header:hover {
  background-color: #e6e6e6;
  /* Hover effect for the header */
}

.icon {
  font-size: 20px;
  max-width: 100%;
  max-height: 100%;
  transition: transform 0.3s ease-in-out;
}

.section.expanded .icon {
  transform: rotate(90deg);
  /* Rotate icon when section is open */
}

.section-header h2 {
  font-size: 24px;
  margin: 0;
}

.tiles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px;
}

.tile {
  width: calc(40%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  border-radius: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;
  height: 30px;
  display: flex;
  /* Use flex display */
  justify-content: center;
  /* Center content vertically */
  align-items: center;
  /* Center content horizontally */
  text-decoration: none;
  /* Remove the underline */
  color: inherit;
}

.tile-link {
  text-decoration: none;
  /* Remove the underline */
  color: inherit;
  /* Inherit the color from the parent container (normal tile text color) */
}

.tile:hover {
  transform: translateY(-5px);
  /* Hover effect to lift the tile slightly */
}

.tile-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* Add this to separate the 3-dot menu from text */
}

.tile-options {
  cursor: pointer;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.popup-option {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.popup-option svg {
  margin-right: 5px;
}

/* Customize colors, fonts, and other styles as per your design preferences */